
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import CardBasicNew from "@/components/cards/CardBasicNew.vue";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import moment from "moment";
import router from "@/router";
import EntityForm from "@/views/catalog/entities/EntityForm.vue";
import EntityTypesSelect from "@/components/catalogs-select/EntityTypesSelect.vue";
import OfficeSelect from "@/components/catalogs-select/OfficeSelect.vue";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  last_page: number;
}

export default defineComponent({
  name: "Entities",
  components: {
    OfficeSelect,
    EntityTypesSelect,
    EntityForm,
    CardBasicNew,
    TableBasicSelect,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const search = ref("");
    const temp = localStorage.getItem("user") as any;
    const user = JSON.parse(temp);
    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };

    const canCreate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "entities: create")
            .length > 0
        );
      }
      return false;
    });

    const canSeeDetails = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "entities: show").length >
          0
        );
      }
      return false;
    });

    const canSeeOffices = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "offices: index").length >
          0
        );
      }
      return false;
    });

    const canEdit = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "entities: update")
            .length > 0
        );
      }
      return false;
    });

    const canDelete = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "entities: delete")
            .length > 0
        );
      }
      return false;
    });

    const canSeeEntityTypes = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "type entities: index"
        ) !== -1
      );
    });

    const totalFiles = computed(() => store.getters.TotalFiles);
    const dataList = ref([]);
    const filtered = ref([]);
    const viewType = ref(user.user_preferences.view_type_items);
    const originalData = ref([]);
    const bls = ref([]);
    const bookings = ref([]);
    const created_date = ref([]);
    const otSelected = ref("");
    const form = ref(false);
    const showDelete = ref(false);
    const cantDelete = ref(false);
    const deleteId = computed(() => store.getters.DeleteId);
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: user.user_preferences.items_per_page,
      total: totalFiles.value,
      last_page: Math.floor(totalFiles.value / 12),
    });
    const queryData = ref({
      entityTypes: [],
      office: [],
      entity: [],
    });

    const header = ref([
      { column: t("iname"), key: "name" },
      { column: t("iemail"), key: "email", type: "email" },
      { column: t("iclient"), key: "client", type: "check", size: "small" },
      {
        column: t("ipotencial_client"),
        key: "ipotencial_client",
        type: "check",
        size: "small",
      },
      {
        column: t("icorrespondent"),
        key: "icorrespondent",
        type: "check",
        size: "small",
      },
      {
        column: t("iforwarder"),
        key: "iforwarder",
        type: "check",
        size: "small",
      },
      {
        column: t("ishipowner"),
        key: "ishipowner",
        type: "check",
        size: "small",
      },
      {
        column: t("iequipmentsupplier"),
        key: "iequipmentsupplier",
        type: "check",
        size: "small",
      },
      { column: t("icarrier"), key: "icarrier", type: "check", size: "small" },
      { column: t("type_entities"), key: "type_entities" },
    ]);

    const tableButtons = ref([
      {
        name: "Editar" as never,
        type: "EDIT" as never,
        color: "primary" as never,
      } as never,
      {
        name: "Eliminar" as never,
        type: "REMOVE" as never,
        color: "danger" as never,
      } as never,
    ]);

    const formatDate = (value) => {
      return moment(value).format("DD/MM/YYYY");
    };

    const createFieldItems = (data) => {
      return data.map(function (element) {
        element.items = {
          iemail: element.email,
          entitytypes: entity_types_function(element),
        };
        return element;
      });
    };

    const getItems = async () => {
      store.commit("setLoadingStatus", true);
      ApiService.query(`/api/entities`, {
        params: {
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage,
          order_by_id: "DESC",
          info: search.value,
          entity_type_id: queryData.value.entityTypes,
          office_id: queryData.value.office,
        },
      }).then(({ data }) => {
        originalData.value = data.entities;
        filtered.value = [];
        dataList.value = createFieldItems(data.entities);
        pagination.value.total = data.pagination.total;
        pagination.value.last_page = data.pagination.last_page;
        filtered.value =
          viewType.value === "card"
            ? data.entities
            : createTableItems(data.entities);
        store.commit("setLoadingStatus", false);
      });
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs(form.value ? "entityCreate" : "entities", [
        { route: "/entities", label: "entities" },
      ]);
      getItems();
      store.commit("removeEDataPaymentTypeDetails");
    });

    const currentPageChange = (val) => {
      pagination.value.page = val;
      getItems();
    };

    const setItemsPerPage = (event) => {
      pagination.value.rowsPerPage =
        typeof event === "object" ? parseInt(event.target.value) : event;
      getItems();
    };

    const onSearch = () => {
      let data = createFieldItems(dataList.value);
      if (viewType.value == "table") {
        data = createTableItems(dataList.value);
      }
      filtered.value = data.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
      if (!filtered.value.length) {
        getItems();
      }
    };

    const createTableItems = (data) => {
      const result = [] as any;
      data.map(function (element) {
        result.push({
          id: element.id,
          name: element.name,
          email: element.email,
          client: element.entity_types.map((x) => x.id).includes(1) ? "1" : "0",
          ipotencial_client: element.entity_types.map((x) => x.id).includes(13)
            ? "1"
            : "0",
          icorrespondent: element.entity_types.map((x) => x.id).includes(16)
            ? "1"
            : "0",
          iforwarder: element.entity_types.map((x) => x.id).includes(12)
            ? "1"
            : "0",
          ishipowner: element.entity_types.map((x) => x.id).includes(7)
            ? "1"
            : "0",
          iequipmentsupplier: element.entity_types.map((x) => x.id).includes(2)
            ? "1"
            : "0",
          icarrier: element.entity_types.map((x) => x.id).includes(4)
            ? "1"
            : "0",
          type_entities: entity_types_function(element, true),
        });
      });
      return result;
    };

    const seeDetails = (entity) => {
      router.push(`/entities/${entity["id"]}/general`);
    };

    const reload = () => {
      getItems();
      form.value = false;
    };

    const actionsButton = (param1, param2, param3) => {
      if (param2 === "EDIT") {
        router.push(`/entities/${param1}/general`);
      }
      if (param2 === "REMOVE") {
        let entity = originalData.value.filter((x) => x["id"] == param1);
        if (entity[0]["can_it_be_deleted"]) {
          deleteDialog(param1);
        } else {
          cantDelete.value = true;
        }
      }
    };

    const createElement = () => {
      form.value = true;
      store.commit("setEntityTypes", []);
      store.commit("setOffices", []);
      store.commit("setSectors", []);
    };

    const deleteDialog = (value) => {
      store.commit("setDeleteId", value);
      showDelete.value = true;
    };

    const deleteElement = () => {
      store.commit("setLoadingStatus", true);
      ApiService.delete(`/api/entities/${deleteId.value}`).then(function () {
        store.commit("setDeleteId", undefined);
        showDelete.value = false;
        getItems();
      });
    };

    const addEntityType = (data) => {
      queryData.value.entityTypes = data;
    };
    const addOffice = (data) => {
      queryData.value.office = data;
    };

    const entity_types_function = (element, table = false) => {
      let entity_types = "";
      let entity_types_priority = "";
      let entity_types_exclude = [1, 13, 16, 12, 7, 2, 4];
      element.entity_types.forEach((item) => {
        if (table) {
          if (entity_types == "" && !entity_types_exclude.includes(item.id)) {
            entity_types = item.name;
          } else {
            if (!entity_types_exclude.includes(item.id)) {
              entity_types += ", " + item.name;
            }
          }
        } else {
          if (entity_types_exclude.includes(item.id)) {
            if (entity_types_priority == "") {
              entity_types_priority = item.name;
            } else {
              entity_types_priority += ", " + item.name;
            }
          } else {
            if (entity_types == "") {
              entity_types = item.name;
            } else {
              entity_types += ", " + item.name;
            }
          }
        }
      });
      let response =
        entity_types_priority != ""
          ? entity_types != ""
            ? entity_types_priority + ", " + entity_types
            : entity_types_priority
          : entity_types;

      return response.length > 70 ? response.substr(0, 70) + "  ..." : response;
    };

    return {
      canCreate,
      canSeeDetails,
      canSeeOffices,
      canSeeEntityTypes,
      canEdit,
      canDelete,
      created_date,
      bls,
      bookings,
      filtered,
      dataList,
      search,
      viewType,
      header,
      tableButtons,
      pagination,
      form,
      otSelected,
      showDelete,
      cantDelete,
      queryData,
      entity_types_function,
      addEntityType,
      addOffice,
      formatDate,
      onSearch,
      getItems,
      currentPageChange,
      setItemsPerPage,
      seeDetails,
      reload,
      actionsButton,
      createElement,
      deleteDialog,
      deleteElement,
      user,
    };
  },
});
