
import { Edit, UploadFilled, Picture } from "@element-plus/icons-vue";
import { computed, defineComponent, onMounted, ref } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { ElNotification } from "element-plus";
import router from "@/router";
import store from "@/store";
import EntityTypesTE from "@/components/entities/tables-editable/EntityTypesTE.vue";
import OfficesTE from "@/components/entities/tables-editable/OfficesTE.vue";
import SectorsTE from "@/components/entities/tables-editable/SectorsTE.vue";
import SynchronizeTE from "@/components/entities/tables-editable/SynchronizeTE.vue";
import Avatar from "@/components/avatar/avatar.vue";
import CommunicationSectionTE from "@/components/entities/tables-editable/CommunicationSectionTE.vue";
import IdentificationSectionTE from "@/components/entities/tables-editable/IdentificationSectionTE.vue";

interface FormCatalog {
  id?: number;
  name: string;
  legal_name: string;
  identification_type_id: string;
  identification: string;
  email: string;
  logo: string;
  contacts: [];
  addresses: [];
  entity_types: [];
  sectors: [];
  offices: [];
  synchronize_entities: [];
}

export default defineComponent({
  name: "EntityForm",
  components: {
    IdentificationSectionTE,
    CommunicationSectionTE,
    Field,
    ErrorMessage,
    SynchronizeTE,
    EntityTypesTE,
    SectorsTE,
    OfficesTE,
    Avatar,
  },
  props: {
    entityId: String,
  },

  setup(props, { emit }) {
    const dataTypes = computed(() => store.getters.EntityTypes);
    const sectorData = computed(() => store.getters.Sectors);
    const officesData = computed(() => store.getters.Offices);
    let communicationsTable = computed(() => store.getters.Communications);
    let identificationsTable = computed(() => store.getters.Identifications);
    const syncData = ref([] as any);
    const active = ref("first");
    const { t } = useI18n();
    const submitButton = ref<HTMLElement | null>(null);
    const logo = ref(undefined) as any;
    const form: any = ref<FormCatalog>({
      name: "",
      legal_name: "",
      identification_type_id: "",
      identification: "",
      email: "",
      logo: "",
      contacts: [],
      addresses: [],
      entity_types: [] as any,
      sectors: [] as any,
      offices: [] as any,
      synchronize_entities: [] as any,
    });

    const createCommunication = () => {
      if (communicationsTable.value.length > 0) {
        let cont = 0;
        let invalidTable = false;
        communicationsTable.value.forEach((item, index) => {
          if (item.media_id === "" || item.value === "") {
            invalidTable = true;
            cont += 1;
          }
        });
        if (!invalidTable) {
          addCommunicationLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rcommunicationtable"),
            type: "error",
          });
        }
      } else {
        addCommunicationLine();
      }
    };

    const addCommunicationLine = () => {
      store.commit("createCommunications", {
        media_id: "",
        value: "",
        remark: "",
        id: undefined,
      });
    };

    const deleteCommunication = (index) => {
      store.commit("removeCommunication", index);
    };
    const createIdentification = () => {
      if (identificationsTable.value.length > 0) {
        let cont = 0;
        let invalidTable = false;
        identificationsTable.value.forEach((item, index) => {
          if (item.identification_type_id === "" || item.value === "") {
            invalidTable = true;
            cont += 1;
          }
        });
        if (!invalidTable) {
          addIdentificationLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("ridentificationtable"),
            type: "error",
          });
        }
      } else {
        addIdentificationLine();
      }
    };

    const addIdentificationLine = () => {
      store.commit("createIdentification", {
        identification_type_id: "",
        value: "",
        id: undefined,
      });
    };

    const deleteIdentification = (index) => {
      store.commit("removeIdentification", index);
    };

    const createSync = () => {
      if (syncData.value.length > 0) {
        let invalidTable = false;
        syncData.value.forEach((item, index) => {
          if (index === syncData.value.length - 1) {
            invalidTable =
              item.synchronize_flag_id === "" || item.external_code === "";
          }
        });
        if (!invalidTable) {
          addLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rflagtabletable"),
            type: "error",
          });
        }
      } else {
        addLine();
      }
    };

    const addLine = () => {
      syncData.value.push({
        synchronize_flag_id: "",
        office_id: "",
        entity_type_id: "",
        external_code: "",
      });
    };

    const deleteSync = (index) => {
      syncData.value.splice(index, 1);
    };

    //offices
    const createOffice = () => {
      store.commit("createOffice", {
        id: 0,
        name: "",
        remarks: "",
      });
    };
    const deleteOffice = (i) => {
      officesData.value.forEach(function (value, index) {
        if (index === i) {
          officesData.value.splice(i, 1);
        }
      });
    };

    const changeImage = (event) => {
      logo.value = event[0];
    };

    const removeImage = () => {
      logo.value = "";
    };

    const isNameRequired = (value) => {
      if (!value) {
        return t("rname");
      }
      return true;
    };

    onMounted(() => {
      store.commit("setCommunications", []);
      store.commit("setIdentification", []);
      communicationsTable = computed(() => store.getters.Communications);
      identificationsTable = computed(() => store.getters.Identifications);
      findContacts();
      if (props.entityId) {
        ApiService.get("/api/entities/" + props.entityId).then(({ data }) => {
          form.value = { ...data };
          data.synchronize_entities.forEach((x) => {
            syncData.value.value.push({
              synchronize_flag_id: x.synchronize_flag_id,
              office_id: x.office_id,
              entity_type_id: x.entity_type_id,
              external_code: x.external_code,
            });
          });
        });
      }
    });

    const address = ref([]) as any;
    const location_list = ref([]) as any;
    const contacts_list = ref([]) as any;
    const companies_list = ref([]) as any;
    const identification_type = ref({
      list: [],
      selected: 0,
    }) as any;
    const office_list = ref([]) as any;
    const entities_type_list = ref([]) as any;
    const sectors_list = ref([]) as any;
    const entities_type_table = ref([]) as any;
    const office_table = ref([]) as any;
    const sectors_table = ref([]) as any;
    const address_type_list = ref([]) as any;
    const addressDialog = ref(false);
    const address_detail = ref({
      street: "",
      location_id: 0,
      number: 0,
      zip_code: "",
      latitude: "",
      address_types: [
        {
          address_id: 1,
          address_type_id: 1,
        },
      ],
      door: "",
      longitude: "",
      entity_id: 0,
      contact_id: 0,
      floor_number: "",
      company_id: 0,
      office_id: 0,
    });
    const resetForm = () => {
      //
    };
    const params_data = ref({
      entity_type: 0,
      office: 0,
      sectors: 0,
    });
    const saveAddress = () => {
      address.value.push({
        street: address_detail.value.street,
        location_id: address_detail.value.location_id,
        number: address_detail.value.number,
        zip_code: address_detail.value.zip_code,
        latitude: address_detail.value.latitude,
        address_types: [
          {
            address_id: 1,
            address_type_id:
              address_detail.value.address_types[0].address_type_id,
          },
        ],
        door: address_detail.value.door,
        longitude: address_detail.value.longitude,
        entity_id: address_detail.value.entity_id,
        contact_id: address_detail.value.contact_id,
        floor_number: address_detail.value.floor_number,
        company_id: address_detail.value.company_id,
        office_id: address_detail.value.office_id,
      });
      addressDialog.value = false;
    };

    const addressDialogContent = () => {
      addressDialog.value = true;
      store.commit("setLoading", false);
      ApiService.get("/api/location").then(function (response) {
        location_list.value = response.data.locations;
        address_detail.value.location_id = response.data.locations[0].id;
      });
      store.commit("setLoading", false);
      ApiService.get("/api/address-types").then(function (response) {
        address_type_list.value = response.data;
        address_detail.value.address_types[0].address_type_id =
          response.data[0].id;
      });
      store.commit("setLoading", false);
      ApiService.get("/api/entities").then(function (response) {
        entities_list.value = response.data;
        contactDetail.value.entity_id = response.data[0].id;
      });
      store.commit("setLoading", false);
      ApiService.get("/api/contact-types").then(function (response) {
        contacts_list.value = response.data;
        address_detail.value.contact_id = response.data[0].id;
      });
      store.commit("setLoading", false);
      ApiService.get("/api/companies").then(function (response) {
        companies_list.value = response.data;
        address_detail.value.company_id = response.data[0].id;
      });
      store.commit("setLoading", false);
      ApiService.get("/api/offices").then(function (response) {
        // office_list.value = response.data;
        // address_detail.value.office_id = response.data[0].id;
      });
    };

    const contacts = ref([]) as any;
    const entities_list = ref([]);
    const contactDialog = ref(false);
    const contactDetail = ref({
      gender: "m",
      entity_type: 1,
      b_date: "",
      name: "",
      lastname: "",
      mobile: "",
      phone: "",
      identification_id: 0,
      entity_id: 0,
      email: "",
    }) as any;
    const addContacts = () => {
      contacts.value.push({
        gender: contactDetail.value.gender,
        contact_type_id: contactDetail.value.contact_type_id,
        born_date: contactDetail.value.b_date,
        name: contactDetail.value.name,
        cellphone: contactDetail.value.mobile,
        phone: contactDetail.value.phone,
        identification: contactDetail.value.identification_id,
        last_name: contactDetail.value.lastname,
        entity_id: contactDetail.value.entity_id,
        email: contactDetail.value.email,
      });
    };
    const findContacts = () => {
      contactDetail.value = {
        gender: "m",
        entity_type: 1,
        b_date: "",
        name: "",
        lastname: "",
        mobile: "",
        phone: "",
        identification_id: 0,
        entity_id: 0,
        email: "",
      };
      contactDialog.value = true;
      store.commit("setLoading", false);
      ApiService.get("/api/sectors").then(function (response) {
        sectors_list.value = response.data;
        params_data.value.sectors = response.data[0].id;
      });
      store.commit("setLoading", false);
      ApiService.get("/api/type-entities").then(function (response) {
        entities_type_list.value = response.data;
        params_data.value.entity_type = response.data[0].id;
      });
      store.commit("setLoading", false);
      ApiService.get("/api/offices/lists?per_page=10").then(function (
        response
      ) {
        office_list.value = response.data.offices;
        // params_data.value.office = response.data[0].id;
      });
      store.commit("setLoading", false);
      ApiService.get("/api/entities/lists?per_page=10").then(function (
        response
      ) {
        entities_list.value = response.data.entities;
        contactDetail.value.entity_id = response.data.entities[0].id;
      });
      store.commit("setLoading", false);
      ApiService.get("/api/identification-type").then(function (response) {
        identification_type.value.list = response.data.identification_type;
        form.value.identification_type_id =
          response.data.identification_type[0].id;
      });
    };
    const saveEntity = () => {
      store.commit("setLoadingStatus", true);
      let flag = true;
      if (
        dataTypes.value.length === 0 ||
        form.value.name === "" ||
        form.value.legal_name === "" ||
        form.value.email === ""
      ) {
        store.commit("setLoadingStatus", false);
        ElNotification({
          title: "Error",
          message: t("rrequiredfields"),
          type: "error",
        });
        flag = false;
      }

      let invalidTable = false;
      if (syncData.value.length > 0) {
        syncData.value.forEach((item, index) => {
          if (index === syncData.value.length - 1) {
            invalidTable =
              item.synchronize_flag_id === "" || item.external_code === "";
          }
        });
        if (invalidTable) {
          store.commit("setLoadingStatus", false);
          ElNotification({
            title: "Error",
            message: t("rflagtabletable"),
            type: "error",
          });
          flag = false;
        }
      }

      if (flag) {
        const formData = new FormData();
        formData.append(
          "entity_types",
          dataTypes.value.map((x) => x.id)
        );
        formData.append(
          "sectors",
          sectorData.value.map((x) => x.id)
        );
        formData.append(
          "offices",
          officesData.value.map((x) => x.id)
        );
        formData.append("synchronize_flags", JSON.stringify(syncData.value));
        if (logo.value) {
          formData.append("logo", logo.value);
        }
        formData.append("name", form.value.name);
        formData.append("legal_name", form.value.legal_name);
        formData.append("email", form.value.email);
        formData.append(
          "communications",
          JSON.stringify(communicationsTable.value)
        );
        formData.append(
          "identifications",
          JSON.stringify(identificationsTable.value)
        );

        ApiService.postAttach("/api/entities", formData).then(function (resp) {
          emit("success", resp);
          router.push(`/entities/${resp.data.id}/general`);
        });
      }
    };
    const next = () => {
      //
    };
    //entities
    const deleteEntity = (i) => {
      dataTypes.value.forEach(function (value, index) {
        if (index === i) {
          dataTypes.value.splice(i, 1);
        }
      });
    };
    const createEntity = () => {
      store.commit("createEntityTypes", {
        id: 0,
        name: "",
        description: "",
      });
    };
    const addEntityType = () => {
      entities_type_list.value.forEach(function (entities_type_data) {
        if (params_data.value.entity_type === entities_type_data.id) {
          entities_type_table.value.push(entities_type_data);
        }
      });
    };
    const deleteEntityType = (index: any) => {
      if (index > -1) {
        entities_type_table.value.splice(index, 1);
      }
    };
    const addOffice = () => {
      office_list.value.forEach(function (office_data) {
        if (params_data.value.office === office_data.id) {
          office_table.value.push(office_data);
        }
      });
    };
    const addSector = () => {
      sectors_list.value.forEach(function (sector_data) {
        if (params_data.value.sectors === sector_data.id) {
          sectors_table.value.push(sector_data);
        }
      });
    };

    const createSector = () => {
      store.commit("createSector", {
        id: 0,
        name: "",
        remarks: "",
      });
    };
    const deleteSector = (i) => {
      sectorData.value.splice(i, 1);
      const dataSector = [] as any;
      sectorData.value.forEach(function (value) {
        dataSector.push(value.id);
      });
    };
    const onSumbit = (value, { resetForm }) => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
    };

    return {
      syncData,
      createSync,
      deleteSync,
      saveEntity,
      sectors_table,
      deleteSector,
      createSector,
      addSector,
      sectors_list,
      office_table,
      deleteOffice,
      addOffice,
      params_data,
      deleteEntityType,
      addEntityType,
      next,
      submitButton,
      form,
      logo,
      onSumbit,
      isNameRequired,
      changeImage,
      removeImage,
      Edit,
      UploadFilled,
      Picture,
      active,
      findContacts,
      contacts,
      entities_list,
      entities_type_list,
      entities_type_table,
      contactDialog,
      contactDetail,
      addContacts,
      address,
      address_detail,
      addressDialog,
      location_list,
      addressDialogContent,
      address_type_list,
      contacts_list,
      companies_list,
      office_list,
      saveAddress,
      resetForm,
      identification_type,
      createEntity,
      deleteEntity,
      createOffice,
      createCommunication,
      addCommunicationLine,
      deleteCommunication,
      createIdentification,
      addIdentificationLine,
      deleteIdentification,
      communicationsTable,
      identificationsTable,
    };
  },
});
